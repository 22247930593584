import React from "react";
import { Box, Heading, Flex } from "@chakra-ui/react";

import EconomicCalendar from "../../components/Terminal/EconomicCalendar";
import EarningCalendar from "../../components/Terminal/EarningCalendar";
import { useSelector } from "react-redux";

const TerminalPage = () => {
  const theme = useSelector((state) => state.themeReducer);

  return (
    <Box
      ml={{ base: "0", md: "60" }}
      minH="95vh"
      bgColor={theme.backgroundColor1}
      position="relative"
      paddingTop="15px"
      paddingBottom="15px"
      paddingInline="5px"
    >
      <Heading as="h1" size="xl" mb="10" textAlign="center">
        Economic & Earning Calendars
      </Heading>
      <Flex direction="column" gap="10">
        <Box>
          <EconomicCalendar />
        </Box>
        <Box>
          <EarningCalendar />
        </Box>
      </Flex>
    </Box>
  );
};

export default TerminalPage;
