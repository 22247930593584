// src/services/companyService.js
export const fetchCompanies = async () => {
  const accessToken = localStorage.getItem("access");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/market-data/companies`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch companies");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching companies:", error);
    throw error;
  }
};
