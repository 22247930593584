import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  VStack,
  Grid,
  GridItem,
  Card,
  CardHeader,
  useBreakpointValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  CheckCircleIcon,
  EditIcon, // Replaced PencilIcon with EditIcon
  ArrowUpIcon,
  TimeIcon,
  LockIcon,
  StarIcon, // Replaced RocketIcon with an alternative
} from "@chakra-ui/icons";
import { useInView } from "react-intersection-observer";
import SolutionHero from "../../assets/SolutionHero.png";
import { useSelector } from "react-redux";

const AnimatedFeatureCard = ({ title, description, delay }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const cardWidth = useBreakpointValue({
    base: "90%",
    sm: "95%",
    md: "80%",
    lg: "85%",
  });

  const cardPadding = useBreakpointValue({
    base: "4",
    sm: "6",
  });

  const titleFontSize = useBreakpointValue({
    base: "sm",
    sm: "md",
  });

  const descriptionFontSize = useBreakpointValue({
    base: "xs",
    sm: "sm",
    md: "md",
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.7, delay }}
    >
      <Card width={cardWidth} boxShadow="lg" mx="auto" mb={6}>
        <CardHeader padding={cardPadding}>
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Heading
              size={titleFontSize}
              textAlign="center"
            >{`${title}`}</Heading>
            <Text fontSize={descriptionFontSize} textAlign="center">
              {description}
            </Text>
          </Flex>
        </CardHeader>
      </Card>
    </motion.div>
  );
};

const ITSolution = () => {
  const [isLargerThan840] = useMediaQuery("(min-width: 840px)");
  const bgColor = useSelector((state) => state.themeReducer.backgroundColor1);

  const whatweoffer = [
    {
      title: "Websites",
      description:
        "High-performing, visually stunning, and tailored to reflect your brand identity.",
      delay: 0,
    },
    {
      title: "Web Applications",
      description:
        "Scalable, secure, and designed to meet your business-specific needs.",
      delay: 0.2,
    },
    {
      title: "Custom Software Development",
      description: "Fully personalized software to streamline your operations.",
      delay: 0.4,
    },
    {
      title: "Mobile Applications",
      description: "User-friendly apps designed to engage your audience.",
      delay: 0.6,
    },
    {
      title: "IT Consulting & Strategy",
      description: "Expert guidance to develop scalable IT strategies.",
      delay: 0.8,
    },
    {
      title: "Cybersecurity Solutions",
      description: "Advanced security to protect your business from threats.",
      delay: 1.0,
    },
    {
      title: "Cloud Solutions",
      description:
        "Migration and management services to leverage cloud technology.",
      delay: 1.2,
    },
  ];
  const features = [
    {
      title: "Real-Time IT Monitoring",
      description:
        "Stay on top of performance and security with live insights and alerts.",
      icon: <CheckCircleIcon color="green.500" />,
    },
    {
      title: "Customizable Dashboards",
      description: "Track metrics and data relevant to your business goals.",
      icon: <ArrowUpIcon color="blue.500" />,
    },
    {
      title: "In-Depth Analytics",
      description:
        "Access historical and real-time data to make informed decisions and forecast trends.",
      icon: <TimeIcon color="purple.500" />,
    },
    {
      title: "Scalability",
      description:
        "Solutions designed to grow with your business, ensuring future readiness.",
      icon: <StarIcon color="orange.500" />,
    },
    {
      title: "Robust Security",
      description:
        "Built-in advanced cybersecurity measures to safeguard your operations.",
      icon: <LockIcon color="red.500" />,
    },
  ];
  const benefits = [
    {
      title: "Tailored Innovation",
      description:
        "We create solutions uniquely suited to your business, ensuring a perfect fit for your needs.",
      icon: <EditIcon color="green.500" />,
    },
    {
      title: "Empowering Startups",
      description:
        "We specialize in helping startups transform their ideas into impactful digital solutions.",
      icon: <StarIcon color="green.500" />, // Example alternative to RocketIcon
    },
    {
      title: "Scalable Growth",
      description:
        "Our technology grows with you, supporting you at every stage of your journey.",
      icon: <ArrowUpIcon color="green.500" />,
    },
    {
      title: "Efficiency & Performance",
      description:
        "Streamlined systems to optimize your operations and improve productivity.",
      icon: <TimeIcon color="green.500" />,
    },
    {
      title: "Peace of Mind",
      description:
        "State-of-the-art security ensures your business stays protected.",
      icon: <LockIcon color="green.500" />,
    },
  ];

  const handleContactClick = () => {
    window.location.href = "http://www.xcapitalterminal.com/";
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box
        height="648px"
        position="relative"
        backgroundImage={`url(${SolutionHero})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition={
          isLargerThan840 ? "center" : "calc(100% - 70%) bottom"
        }
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(0, 0, 0, 0.4)"
        />
        <Flex
          position="absolute"
          bottom="15%"
          left="5%"
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          maxWidth={isLargerThan840 ? "45%" : "80%"}
          color="white"
        >
          <Heading
            as="h1"
            fontSize={isLargerThan840 ? "48px" : "28px"}
            fontWeight="800"
            marginBottom="28px"
          >
            X CAPITAL IT SOLUTIONS
          </Heading>
          <Text
            fontSize={isLargerThan840 ? "24px" : "20px"}
            marginBottom="24px"
            maxWidth={isLargerThan840 ? "90%" : "100%"}
          >
            Empowering businesses with tailored, cutting-edge IT solutions to
            scale, innovate, and thrive in the digital world.
          </Text>
          <Button
            bg="white"
            color="gray.700"
            width="160px"
            height="40px"
            borderRadius="12px"
            fontSize="20px"
            _hover={{ bg: "gray.100" }}
          >
            Get Started
          </Button>
        </Flex>
      </Box>

      {/* What we offer Section */}
      <Box bg={bgColor} width="full" ml={"0%"} py="60px">
        <VStack spacing="40px" align="center" w="100%" mx="auto" px={4}>
          <Heading mb="24px">What We Offer</Heading>

          {/* Using Grid to display cards in two columns */}
          <Grid
            templateColumns={{ base: "1fr", sm: "1fr", md: "1fr 1fr" }}
            gap={6}
            maxW="1200px"
            mx="auto"
          >
            {whatweoffer.map((feature, index) => (
              <GridItem
                key={index}
                colSpan={
                  index === whatweoffer.length - 1 ? { base: 1, md: 2 } : 1
                }
              >
                <AnimatedFeatureCard {...feature} />
              </GridItem>
            ))}
          </Grid>
        </VStack>
      </Box>

      <Box bg={bgColor} width="full" ml={"0%"} py="60px">
        <VStack spacing="40px" align="center" w="100%" mx="auto" px={4}>
          <Heading mb="24px">Key Features</Heading>
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} // 1 column on mobile, 3 columns on medium and larger screens
            gap={6}
          >
            {features.map((item, index) => (
              <GridItem key={index}>
                <Box
                  p="6"
                  borderRadius="md"
                  _hover={{
                    transform: "scale(1.05)",
                    boxShadow: "lg",
                    transition: "all 0.3s ease",
                  }}
                >
                  <Flex align="center" justify="center" mb="4">
                    {/* Adjust the size of the icon here */}
                    {React.cloneElement(item.icon, { boxSize: "40px" })}
                  </Flex>
                  <Text fontSize="xl" fontWeight="semibold" textAlign="center">
                    {item.title}
                  </Text>
                  <Text fontSize="md" textAlign="center" mt="2">
                    {item.description}
                  </Text>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      </Box>

      <Box bg={bgColor} width="full" ml={"0%"} py="60px">
        <VStack spacing="40px" align="center" w="100%" mx="auto" px={4}>
          <Heading mb="24px">
            Benefits of Choosing X CAPITAL IT SOLUTIONS
          </Heading>
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} // One column on mobile, 3 columns on larger screens
            gap={4}
          >
            {benefits.map((benefit, index) => (
              <GridItem key={index} textAlign="center">
                <Box>{benefit.icon}</Box>
                <Text fontSize="xl" fontWeight="semibold">
                  {benefit.title}
                </Text>
                <Text fontSize="md">{benefit.description}</Text>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      </Box>

      {/* Call to Action */}
      <Box bg="yellow.500" color="white" py={10} textAlign="center">
        <Heading mb={4}>
          Let’s Build the Future of Your Business Together!
        </Heading>
        <Button
          size="lg"
          bg="white"
          color="yellow.500"
          onClick={handleContactClick}
          _hover={{ bg: "gray.300" }}
        >
          Get Started
        </Button>
      </Box>
    </Box>
  );
};

export default ITSolution;
