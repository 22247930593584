import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Heading,
  Text,
  VStack,
  HStack,
  Badge,
  useColorModeValue,
  Switch,
  Center,
  Select,
  Spinner,
} from "@chakra-ui/react";
import { AwardIcon, CheckIcon } from "lucide-react";
import Questions from "../components/questions/Questions";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../actions/product";
import { useNavigate } from "react-router-dom";
import { load_user } from "../actions/auth";
import { setCart } from "../actions/cart";

const PricingCard = ({
  period,
  id,
  title,
  price,
  features,
  buttonText,
  isPopular,
  isDark,
}) => {
  const bgColor = useColorModeValue(
    isDark ? "#2D2D2D" : "white",
    isDark ? "gray.700" : "gray.800"
  );
  const textColor = useColorModeValue(
    isDark ? "white" : "#2D2D2D",
    isDark ? "white" : "#2D2D2D"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const role = useSelector((state) => state.auth.user?.role);
  const handleClick = () => {
    if (isAuth) {
      if (role === "Client" && (/data/i.test(title) || /articl/i.test(title))) {
        dispatch(setCart(id, period));
        window.location.href = "/checkout";
      }
    } else navigate("/login");
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={6}
      backgroundColor={bgColor}
      color={textColor}
      position="relative"
      display="flex"
      flexDirection="column"
      width="100%"
      minW="310px"
      maxW="320px"
      height="100%"
    >
      {isPopular && (
        <Badge colorScheme="red" position="absolute" top={2} right={2}>
          Popular
        </Badge>
      )}
      <VStack align="stretch" spacing={4} flex={1}>
        <Text
          fontSize="3xl"
          fontWeight="bold"
          textAlign="center"
          mb={4}
          whiteSpace="pre-wrap"
        >
          {title}
        </Text>
        <VStack align="stretch" spacing={2} flex={1}>
          {features.map((feature, index) => (
            <Box key={index}>
              <HStack spacing={2}>
                <CheckIcon size={16} />
                <Text fontWeight="bold">{feature.title}</Text>
              </HStack>
              <Text fontSize="sm" color={textColor}>
                {feature.description}
              </Text>
            </Box>
          ))}
        </VStack>
        <Box mt="auto">
          {price && (
            <Heading size="md" textAlign="center" mb={4}>
              {price}
            </Heading>
          )}
          <Button
            colorScheme={isDark ? "whiteAlpha" : "blackAlpha"}
            width="100%"
            onClick={handleClick}
          >
            {buttonText}
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};

const PricingPage = () => {
  const dispatch = useDispatch();
  const [isAnnual, setIsAnnual] = React.useState(false);
  const terminalInf = useSelector(
    (state) => state.productReducer.products
  )?.filter((pr) => /terminal/i.test(pr.title));
  const dataInf = useSelector((state) => state.productReducer.products)?.filter(
    (pr) => /data/i.test(pr.title)
  );
  const articleInf = useSelector(
    (state) => state.productReducer.products
  )?.filter((pr) => /article/i.test(pr.title));
  const intelligenceInf = useSelector(
    (state) => state.productReducer.products
  )?.filter((pr) => /intelligence/i.test(pr.title));
  const [currencies, setCurrencies] = useState(null);
  const [selectedCurrencies, setSelectedCurrency] = useState("MAD");
  const [articlePricings, setArticlePricings] = useState({
    monthlyPrice: articleInf[0]?.monthlyPrice,
    yearlyPrice: articleInf[0]?.yearlyPrice,
  });
  const [dataPricings, setDataPricings] = useState({
    monthlyPrice: dataInf[0]?.monthlyPrice,
    yearlyPrice: dataInf[0]?.yearlyPrice,
  });
  useEffect(() => {
    const fetchCurrency = async () => {
      const response = await fetch(
        "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/mad.json",
        {
          method: "GET",
        }
      );
      await response.json().then((data) => {
        setCurrencies(data);
      });
    };
    fetchCurrency();
    dispatch(getProducts());
    dispatch(load_user());
  }, []);
  useEffect(() => {
    if (selectedCurrencies === "MAD") {
      setArticlePricings({
        monthlyPrice: articleInf[0]?.monthlyPrice * 1,
        yearlyPrice: articleInf[0]?.yearlyPrice * 1,
      });
      setDataPricings({
        monthlyPrice: dataInf[0]?.monthlyPrice * 1,
        yearlyPrice: dataInf[0]?.yearlyPrice * 1,
      });
    } else if (selectedCurrencies === "EUR") {
      setArticlePricings({
        monthlyPrice: articleInf[0]?.monthlyPrice * currencies?.mad.eur,
        yearlyPrice: articleInf[0]?.yearlyPrice * currencies?.mad.eur,
      });
      setDataPricings({
        monthlyPrice: dataInf[0]?.monthlyPrice * currencies?.mad.eur,
        yearlyPrice: dataInf[0]?.yearlyPrice * currencies?.mad.eur,
      });
    } else {
      setArticlePricings({
        monthlyPrice: articleInf[0]?.monthlyPrice * currencies?.mad.usd,
        yearlyPrice: articleInf[0]?.yearlyPrice * currencies?.mad.usd,
      });
      setDataPricings({
        monthlyPrice: dataInf[0]?.monthlyPrice * currencies?.mad.usd,
        yearlyPrice: dataInf[0]?.yearlyPrice * currencies?.mad.usd,
      });
    }
  }, [selectedCurrencies, dataInf, articleInf, intelligenceInf, terminalInf]);
  return (
    <>
      {articleInf[0] && dataInf[0] ? (
        <Container maxW="container.xl" py={10}>
          <VStack spacing={8} align="center">
            <Heading size="2xl">Pricing</Heading>
            <Text textAlign="center" fontSize="lg">
              Choose the right X CAPITAL solution for your investment needs.
              <br />
              Upgrade for advanced features, deeper insights, and comprehensive
              market intelligence.
            </Text>
            <HStack>
              <Text>Monthly</Text>
              <Switch
                size="lg"
                isChecked={isAnnual}
                onChange={() => setIsAnnual(!isAnnual)}
              />
              <Text>Annually</Text>
              <Badge colorScheme="green">Save 15%</Badge>
            </HStack>
            <Select
              width={"200px"}
              fontSize={"14px"}
              onChange={(e) => setSelectedCurrency(e.target.value)}
            >
              <option value="MAD" selected>
                MAD
              </option>
              <option value="EUR">EUR</option>
              <option value="USD">USD</option>
            </Select>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
              gap={8}
              w="full"
            >
              <PricingCard
                period={isAnnual ? "yearly" : "monthly"}
                title="X CAPITAL TERMINAL"
                buttonText="Get Started"
                isDark
                features={[
                  {
                    title: "Real-Time Data",
                    description:
                      "Stay updated with live market data to never miss a market movement.",
                  },
                  {
                    title: "Advanced Analytics",
                    description:
                      "Utilize AI and machine learning algorithms to analyze market trends and patterns.",
                  },
                  {
                    title: "Deep Insights",
                    description:
                      "Gain in-depth insights into market dynamics to make strategic investment decisions.",
                  },
                ]}
              />
              <PricingCard
                period={isAnnual ? "yearly" : "monthly"}
                title="X CAPITAL INTELLIGENCE"
                buttonText="Get Started"
                features={[
                  {
                    title: "Predictive Analytics",
                    description:
                      "Use AI to forecast market trends and identify potential investment opportunities.",
                  },
                  {
                    title: "Custom Reports",
                    description:
                      "Generate detailed reports tailored to your specific needs and investment strategies.",
                  },
                  {
                    title: "Market Sentiment Analysis",
                    description:
                      "Understand market sentiment through advanced data analysis techniques.",
                  },
                ]}
              />
              <PricingCard
                period={isAnnual ? "yearly" : "monthly"}
                id={articleInf[0].id}
                title="X CAPITAL Articles"
                price={
                  isAnnual
                    ? typeof articlePricings?.yearlyPrice === "number"
                      ? `${articlePricings.yearlyPrice.toFixed(
                          2
                        )} ${selectedCurrencies}/year`
                      : "N/A"
                    : typeof articlePricings?.monthlyPrice === "number"
                    ? `${articlePricings.monthlyPrice.toFixed(
                        2
                      )} ${selectedCurrencies}/month`
                    : "N/A"
                }
                buttonText="Get Started"
                features={[
                  {
                    title: "Expert Analysis",
                    description:
                      "Read articles written by industry experts and market analysts.",
                  },
                  {
                    title: "Market Updates",
                    description:
                      "Stay informed with regular updates on market movements and economic developments.",
                  },
                  {
                    title: "Educational Content",
                    description:
                      "Access a wealth of educational resources to enhance your financial knowledge.",
                  },
                ]}
              />
              <PricingCard
                period={isAnnual ? "yearly" : "monthly"}
                id={dataInf[0].id}
                title="X CAPITAL        DATA"
                buttonText="Get Started"
                features={[
                  {
                    title: "Extensive Database",
                    description:
                      "Access a vast repository of historical and real-time financial data.",
                  },
                  {
                    title: "Economic Indicators",
                    description:
                      "Monitor key economic indicators that impact market performance.",
                  },
                  {
                    title: "Customizable Data Feeds",
                    description:
                      "Receive data feeds tailored to your specific research and analytical needs.",
                  },
                ]}
                price={
                  isAnnual
                    ? typeof dataPricings?.yearlyPrice === "number"
                      ? `${dataPricings.yearlyPrice.toFixed(
                          2
                        )} ${selectedCurrencies}/year`
                      : "N/A"
                    : typeof dataPricings?.monthlyPrice === "number"
                    ? `${dataPricings.monthlyPrice.toFixed(
                        2
                      )} ${selectedCurrencies}/month`
                    : "N/A"
                }
              />

              <PricingCard
                // period={isAnnual ? "yearly" : "monthly"}
                id={dataInf[0].id}
                title="X CAPITAL              IT SOLTUIONS"
                buttonText="Get Started"
                features={[
                  {
                    title: "Tailored Innovation",
                    description:
                      "We create solutions uniquely suited to your business, ensuring a perfect fit for your needs.",
                  },
                  {
                    title: "Empowering Startups",
                    description:
                      "We specialize in helping startups transform their ideas into impactful digital solutions.",
                  },
                  {
                    title: "Scalable Growth",
                    description:
                      "Our technology grows with you, supporting you at every stage of your journey.",
                  },
                  {
                    title: "Efficiency & Performance",
                    description:
                      "Streamlined systems to optimize your operations and improve productivity.",
                  },
                  {
                    title: "Peace of Mind",
                    description:
                      "State-of-the-art security ensures your business stays protected.",
                  },
                ]}
                d
              />
            </Grid>
          </VStack>
        </Container>
      ) : (
        <Spinner />
      )}
      <Center>
        <Questions />
      </Center>
    </>
  );
};

export default PricingPage;
