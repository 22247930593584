import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Checkbox,
  Button,
  useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { fetchCompanies } from "../../services/api/companyAPI/fetchCompanies";
import { useSelector } from "react-redux";
import { postEarningCalendar } from "../../services/api/earningCalendarAPI/postEarningCalendar";

const EarningCalendar = () => {
  const theme = useSelector((state) => state.themeReducer);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    company: "",
    date: "",
    before_open: false,
    after_close: false,
  });

  const companyOptions = companies.map((company) => ({
    value: company.id,
    label: company.name,
  }));

  const toast = useToast();

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const companiesData = await fetchCompanies();
        setCompanies(companiesData);
      } catch (error) {
        console.error("Error loading companies:", error);
      } finally {
        setLoading(false);
      }
    };

    loadCompanies();
  }, []);

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value, type, checked } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        company: e ? e.value : "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      for (const [key, value] of Object.entries(formData)) {
        if (key !== "before_open" && key !== "after_close" && !value) {
          toast({
            title: "Validation Error",
            description: `${
              key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")
            } is required.`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }
      const response = await postEarningCalendar(formData);
      console.log("Earning calendar response:", response);
      toast({
        title: "Success",
        description: "Earning calendar data submitted successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setFormData({
        company: "",
        date: "",
        before_open: false,
        after_close: false,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to submit earning calendar data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error submitting earning calendar data:", error);
    }
  };

  return (
    <Box
      ml={{ base: "1%", md: "5%", lg: "20%" }}
      bgColor={theme.backgroundColor2}
      w={{ base: "98%", md: "90%", lg: "60%" }}
      p="25px"
      borderRadius="15px"
      boxShadow="0px 1.92px 3.84px 0px rgba(0, 0, 0, 0.1)"
    >
      <Heading as="h3" size="md" mb="5">
        Earning Calendar
      </Heading>
      <form onSubmit={handleSubmit}>
        <Flex direction="column" gap={5}>
          <FormControl>
            <FormLabel>
              Company <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Select
              isLoading={loading}
              isClearable
              name="company"
              options={companyOptions}
              value={companyOptions.find(
                (option) => option.value === formData.company
              )}
              onChange={handleChange}
              isDisabled={loading}
              placeholder="Select a company"
              styles={{
                control: (base, state) => ({
                  ...base,
                  backgroundColor: theme.backgroundColor2,
                  width: "100%",
                  borderColor: theme.grayScales2,
                  boxShadow: state.isFocused ? "0 0 0 1px black" : "none",
                }),
                singleValue: (base) => ({
                  ...base,
                  color: theme.colorP,
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused
                    ? "lightgray"
                    : state.isSelected
                    ? theme.backgroundColor2
                    : "white",
                  color: state.isSelected ? "white" : "black",
                  ":active": {
                    backgroundColor: theme.backgroundColor2,
                  },
                }),
                placeholder: (base) => ({
                  ...base,
                  color: loading ? "#aaa" : theme.color2,
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: "white",
                }),
                loadingIndicator: (base) => ({
                  ...base,
                  color: "red",
                  fontSize: "7px",
                }),
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Date <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              color={theme.color2}
            />
          </FormControl>
          <FormControl>
            <Checkbox
              name="before_open"
              isChecked={formData.before_open}
              onChange={handleChange}
            >
              Before Open
            </Checkbox>
          </FormControl>
          <FormControl>
            <Checkbox
              name="after_close"
              isChecked={formData.after_close}
              onChange={handleChange}
            >
              After Close
            </Checkbox>
          </FormControl>
          <Button
            type="submit"
            backgroundColor={theme.grayScales}
            color={theme.grayScales === "black" ? "white" : "black"}
            _hover={{
              backgroundColor:
                theme.grayScales === "black" ? "gray.700" : "gray.300",
              color: theme.grayScales === "black" ? "white" : "black",
            }}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default EarningCalendar;
