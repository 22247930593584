import axios from "axios";

export const postEconomicCalendar = async (formData) => {
  const accessToken = localStorage.getItem("access");

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/macro-data/economic-calendar`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error details:", error.response.data);
    } else if (error.request) {
      console.error("No response received from server:", error.request);
    } else {
      console.error("Error setting up the request:", error.message);
    }
    throw error;
  }
};
