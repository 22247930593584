import React from "react";
import {
  Flex,
  Heading,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Text,
  Grid,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MotionFlex = motion(Flex);

function ProductsSection() {
  const bg = useSelector((state) => state.themeReducer.backgroundColor1);
  const descriptionColor = useColorModeValue("gray.800", "whiteAlpha.900");

  return (
    <>
      <Flex align="center" justify="center" marginTop="40px">
        <Heading fontSize={{ base: "2xl", md: "4xl" }}>Our Solutions</Heading>
      </Flex>
      <Flex
        align="center"
        justify="center"
        flexWrap="wrap"
        gap={{ base: "20px", md: "32px" }}
        padding={{ base: "24px", md: "80px" }}
      >
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          gap={8}
          w="full"
          maxW="1200px"
        >
          {[
            {
              title: "X CAPITAL TERMINAL",
              description:
                "X CAPITAL TERMINAL is your gateway to comprehensive financial market data and analytics. With real-time and historical data on stocks, bonds, and more, our terminal provides traders, investors, and businesses with the insights they need to make informed decisions.",
              link: "/wait-list",
              buttonText: "Book a demo",
            },
            {
              title: "X CAPITAL INTELLIGENCE",
              description:
                "X CAPITAL INTELLIGENCE is the engine driving data-driven decision-making. Leveraging AI and machine learning, our platform analyzes datasets to uncover trends and generate actionable insights.",
              link: "/wait-list",
              buttonText: "Book a demo",
            },
            {
              title: "X CAPITAL DATA",
              description:
                "Access comprehensive financial market data at your fingertips with X CAPITAL DATA. From stock prices and trading volumes to economic indicators, our data platform offers a wealth of information.",
              link: "/solutions/x-capital-data",
              buttonText: "Start Now",
            },
            {
              title: "X CAPITAL ARTICLES",
              description:
                "Explore the latest trends, analysis, and insights from X CAPITAL experts. Covering market trends, investment strategies, and industry developments, our articles help you navigate financial markets.",
              link: "/solutions/x-capital-articles",
              buttonText: "Start Now",
            },
            {
              title: "X CAPITAL IT SOLUTIONS",
              description:
                "Empowering businesses with tailored, cutting-edge IT solutions to scale, innovate, and thrive in the digital world.",
              link: "/it-solutions",
              buttonText: "Start Now",
            },
          ].map((item, index) => (
            <MotionFlex
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >
              <Card
                align="center"
                width="100%"
                height="auto"
                boxShadow="lg"
                bgColor={bg}
                transition="transform 0.3s ease, box-shadow 0.3s ease"
                _hover={{
                  transform: "scale(1.05)",
                  boxShadow: "2xl",
                }}
                rounded="lg"
                overflow="hidden"
              >
                <CardHeader bg="gray.100" w="full" p="4">
                  <Heading
                    size="md"
                    textAlign="center"
                    color="black"
                    fontWeight="700"
                  >
                    {item.title}
                  </Heading>
                </CardHeader>
                <CardBody p="6">
                  {/* <Text fontSize="sm" textAlign="center" color="gray.700"> */}
                  <Text
                    fontSize="sm"
                    textAlign="center"
                    color={descriptionColor}
                  >
                    {item.description}
                  </Text>
                </CardBody>
                <CardFooter>
                  <Link to={item.link}>
                    <Button
                      bg="yellow.400"
                      color="black"
                      _hover={{
                        bg: "yellow.500",
                        color: "white",
                      }}
                    >
                      {item.buttonText}
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </MotionFlex>
          ))}
        </Grid>
      </Flex>
    </>
  );
}

export default ProductsSection;
