export const fetchCountries = async () => {
  const accessToken = localStorage.getItem("access");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/market-data/countries`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch countries");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching countries:", error);
    throw error;
  }
};
