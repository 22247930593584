import {
  Box,
  Flex,
  Image,
  Button,
  Hide,
  useColorMode,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import logo from "../assets/logo.svg";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/auth";
import { MdOutlineDarkMode } from "react-icons/md";
import { FiShoppingCart, FiSun } from "react-icons/fi";
import { toggleMode } from "../actions/theme";
import { FiCommand } from "react-icons/fi";
import { getCart } from "../actions/cart";

const navLinks = [
  { name: "Pricing", path: "/pricing" },
  { name: "About Us", path: "/about-us" },
];

const solutionLinks = [
  { name: "X CAPITAL TERMINAL", path: "/solutions/terminal" },
  { name: "X CAPITAL INTELLIGENCE", path: "/solutions/intelligence" },
  { name: "X CAPITAL DATA", path: "/solutions/data" },
  { name: "X CAPITAL ARTICLES", path: "/solutions/articles" },
  { name: "X CAPITAL IT SOLUTIONS", path: "/it-solutions" },
];

export default function NavBar() {
  const [isOpen, setOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const role = useSelector((state) => state.auth.user?.role);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.cartReducer.cartElements);
  const grayScales = useSelector((state) => state.themeReducer.grayScales);

  const { colorMode, toggleColorMode } = useColorMode();

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/pricing");
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  useEffect(() => {
    dispatch(toggleMode(colorMode));
  }, [colorMode]);

  useEffect(() => {
    if (isAuthenticated) dispatch(getCart());
  }, [isAuthenticated]);
  return (
    <Box
      as={"nav"}
      bg={"#2D2D2D"}
      color={"white"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      padding={"7px 10px"} // Reduced padding
      // height={"60px"} // Set a specific height
    >
      <Flex gap={{ base: "10px", lg: "18px" }} align="center">
        <Link to="/">
          <Image
            src={logo}
            alt="XCAPITAL LOGO"
            width={"100px"}
            height={"100px"}
          />
        </Link>
        {isAuthenticated ? (
          <Box fontSize={"20px"} cursor={"pointer"}>
            <Hide above="840px">
              <Link to={`x-capital/terminal/${role}`}>
                <FiCommand fontSize={"30px"} />
              </Link>
            </Hide>
          </Box>
        ) : null}
      </Flex>
      <Hide below="840px">
        <Box
          as={"ul"}
          display={{ base: "none", md: "flex" }}
          gap={"40px"}
          alignItems="center"
        >
          {isAuthenticated ? (
            <Box fontSize={"20px"} cursor={"pointer"} listStyleType={"none"}>
              <Link to={`x-capital/terminal/${role}`}>DashBoard</Link>
            </Box>
          ) : null}
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Box as={"li"} listStyleType={"none"} cursor={"pointer"}>
                <Box fontSize="20px">Solutions</Box>
              </Box>
            </PopoverTrigger>
            <PopoverContent
              bg="#2D2D2D"
              color="white"
              borderRadius="md"
              boxShadow="lg"
              mt="2"
              p="2"
              maxWidth="240px"
              minWidth="190px"
            >
              <PopoverBody>
                {solutionLinks.map((link, index) => (
                  <Box
                    key={index}
                    as={Link}
                    to={link.path}
                    display="block"
                    padding="5px 10px"
                    borderRadius="md"
                    _hover={{ backgroundColor: "#d1d3e0" }}
                    marginY="2px"
                  >
                    {link.name}
                  </Box>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
          {navLinks.map((link, index) => (
            <Box
              key={index}
              as={"li"}
              fontSize={"20px"}
              cursor={"pointer"}
              listStyleType={"none"}
              margin={"10px 0"}
            >
              <Link to={link.path}>{link.name}</Link>
            </Box>
          ))}
          <Flex gap={"20px"} paddingRight={"20px"} alignItems="center">
            {isAuthenticated ? (
              <Flex
                marginLeft="20px"
                direction={"row"}
                gap={"20px"}
                align={"center"}
              >
                <Button
                  _hover={"none"}
                  bg={grayScales === "black" ? "white" : "black"}
                  color={grayScales}
                  width={"90px"}
                  height={"44px"}
                  borderRadius={"20px"}
                  cursor={"pointer"}
                  fontSize={"16px"}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
                {role === "Client" ? (
                  <Box
                    position={"relative"}
                    onClick={() => navigate("/checkout")}
                  >
                    <Flex
                      bg={"white"}
                      color={"black"}
                      position={"absolute"}
                      top={"-2px"}
                      right={"-7px"}
                      width={"15px"}
                      height={"15px"}
                      justify={"space-around"}
                      align={"center"}
                      borderRadius={"50%"}
                    >
                      <Text fontSize={"13px"}>
                        {session ? Object.keys(session).length : 0}
                      </Text>
                    </Flex>
                    <FiShoppingCart fontSize="25px" cursor="pointer" />
                  </Box>
                ) : null}
              </Flex>
            ) : (
              <>
                <Button
                  _hover={"none"}
                  bg={"white"}
                  color={"black"}
                  width={"90px"}
                  height={"44px"}
                  borderRadius={"20px"}
                  cursor={"pointer"}
                  fontSize={"16px"}
                  onClick={handleLogin}
                  transition="background-color 0.5s, color 0.5s"
                >
                  Login
                </Button>
                <Button
                  _hover={"none"}
                  bg={"#697565"}
                  color={"white"}
                  width={"90px"}
                  height={"44px"}
                  borderRadius={"20px"}
                  cursor={"pointer"}
                  fontSize={"16px"}
                  onClick={handleSignUp}
                  transition="background-color 0.5s, color 0.5s"
                >
                  Subscribe
                </Button>
              </>
            )}
            <Flex marginLeft="20px" direction={"row"} gap={"20px"}>
              {colorMode === "light" ? (
                <MdOutlineDarkMode
                  fontSize="25px"
                  cursor="pointer"
                  onClick={toggleColorMode}
                />
              ) : (
                <FiSun
                  fontSize="25px"
                  cursor="pointer"
                  onClick={toggleColorMode}
                />
              )}
            </Flex>
          </Flex>
        </Box>
      </Hide>
      <Hide above="840px">
        <Button onClick={openMenu}>
          <HamburgerIcon />
        </Button>
        {isOpen && (
          <Flex
            direction={"column"}
            gap={"24px"}
            width={"350px"}
            bg={"white"}
            height={"100%"}
            position={"fixed"}
            zIndex={"1"}
            bottom={"0"}
            right={"0"}
            overflowX={"hidden"}
            transition={"1s"}
            paddingBottom={"60px"}
          >
            <Flex
              align={"center"}
              padding={"20px"}
              width={"100%"}
              justify={"flex-end"}
              height={"80px"}
            >
              <Button onClick={closeMenu} size={"lg"}>
                <CloseIcon />
              </Button>
            </Flex>
            <Flex as={"ul"} direction={"column"} padding={"20px"}>
              <Popover trigger="hover" placement="bottom-start">
                <PopoverTrigger>
                  <Box as={"li"} listStyleType={"none"} cursor={"pointer"}>
                    <Box fontSize="20px">Solutions</Box>
                  </Box>
                </PopoverTrigger>
                <PopoverContent
                  bg="white"
                  color="black"
                  borderRadius="md"
                  boxShadow="lg"
                  mt="2"
                  p="4"
                  minWidth="200px"
                >
                  <PopoverBody>
                    {solutionLinks.map((link, index) => (
                      <Box
                        key={index}
                        as={Link}
                        to={link.path}
                        display="block"
                        padding="8px 16px"
                        borderRadius="md"
                        _hover={{ backgroundColor: "#e9eaf7" }}
                        marginY="4px"
                      >
                        {link.name}
                      </Box>
                    ))}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              {navLinks.map((link, index) => (
                <Box
                  key={index}
                  as={"li"}
                  fontSize={"24px"}
                  cursor={"pointer"}
                  listStyleType={"none"}
                  margin={"4px"}
                  width={"280px"}
                  padding={"12px"}
                  color={"black"}
                  _hover={{ backgroundColor: "#e9eaf7" }}
                  borderRadius={"12px"}
                >
                  <Link to={link.path}>{link.name}</Link>
                </Box>
              ))}
              {!isAuthenticated && (
                <Flex
                  gap={"20px"}
                  paddingRight={"20px"}
                  alignItems="center"
                  padding={"12px"}
                >
                  <Button
                    _hover={"none"}
                    bg={"white"}
                    color={"black"}
                    width={"140px"}
                    height={"44px"}
                    borderRadius={"20px"}
                    cursor={"pointer"}
                    fontSize={"20px"}
                    onClick={handleLogin}
                    transition="background-color 0.5s, color 0.5s"
                  >
                    Login
                  </Button>
                  <Button
                    _hover={"none"}
                    bg={"#697565"}
                    color={"white"}
                    width={"140px"}
                    height={"44px"}
                    borderRadius={"20px"}
                    cursor={"pointer"}
                    fontSize={"20px"}
                    onClick={handleSignUp}
                    transition="background-color 0.5s, color 0.5s"
                  >
                    Subscribe
                  </Button>
                </Flex>
              )}
              {isAuthenticated && (
                <Box
                  key={"dashboard"}
                  as={"li"}
                  fontSize={"24px"}
                  cursor={"pointer"}
                  listStyleType={"none"}
                  margin={"4px"}
                  width={"280px"}
                  padding={"12px"}
                  color={"black"}
                  _hover={{ backgroundColor: "#e9eaf7" }}
                  borderRadius={"12px"}
                >
                  <Link to={`x-capital/terminal/${role}`}>DashBoard3</Link>
                </Box>
              )}
              {isAuthenticated && (
                <Button
                  _hover={"none"}
                  bg={"black"}
                  color={"white"}
                  width={"140px"}
                  height={"44px"}
                  borderRadius={"20px"}
                  cursor={"pointer"}
                  fontSize={"20px"}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              )}
            </Flex>
          </Flex>
        )}
      </Hide>
    </Box>
  );
}
