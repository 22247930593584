import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { fetchCountries } from "../../services/api/CountryApi/fetchCountries";
import { useSelector } from "react-redux";
import { postEconomicCalendar } from "../../services/api/economicsCalendarAPI/postEconomicCalendar";

const EconomicCalendar = () => {
  const theme = useSelector((state) => state.themeReducer);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    country: "",
    date: "",
    event: "",
    impact: "",
    actual: "",
    forecast: "",
    previous: "",
  });

  const countryOptions = countries.map((country) => ({
    value: country.id,
    label: country.name,
  }));

  const toast = useToast();

  useEffect(() => {
    const loadCountries = async () => {
      try {
        const countriesData = await fetchCountries();
        setCountries(countriesData);
      } catch (error) {
        console.error("Error loading countries:", error);
      } finally {
        setLoading(false);
      }
    };

    loadCountries();
  }, []);

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        country: e ? e.value : "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      for (const [key, value] of Object.entries(formData)) {
        if (!value) {
          toast({
            title: "Validation Error",
            description: `${
              key.charAt(0).toUpperCase() + key.slice(1)
            } is required.`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }

      const response = await postEconomicCalendar(formData);
      console.log("postEconomicCalendar Data:", response);
      toast({
        title: "Success",
        description: "Economic calendar event submitted successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setFormData({
        country: "",
        date: "",
        event: "",
        impact: "",
        actual: "",
        forecast: "",
        previous: "",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to submit economic calendar event.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      ml={{ base: "1%", md: "5%", lg: "20%" }}
      bgColor={theme.backgroundColor2}
      w={{ base: "98%", md: "90%", lg: "60%" }}
      p="25px"
      borderRadius="15px"
      boxShadow="0px 1.92px 3.84px 0px rgba(0, 0, 0, 0.1)"
    >
      <Heading as="h3" size="md" mb="5">
        Economic Calendar
      </Heading>
      <form onSubmit={handleSubmit}>
        <Flex direction="column" gap={5}>
          <FormControl>
            <FormLabel>
              Country <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Select
              isLoading={loading}
              isClearable
              name="country"
              options={countryOptions}
              value={countryOptions.find(
                (option) => option.value === formData.country
              )}
              onChange={handleChange}
              isDisabled={loading}
              placeholder="Select a country"
              styles={{
                control: (base, state) => ({
                  ...base,
                  backgroundColor: theme.backgroundColor2,
                  width: "100%",
                  borderColor: theme.grayScales2,
                  boxShadow: state.isFocused ? "0 0 0 1px black" : "none",
                }),
                singleValue: (base) => ({
                  ...base,
                  color: theme.colorP,
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused
                    ? "lightgray"
                    : state.isSelected
                    ? theme.backgroundColor2
                    : "white",
                  color: state.isSelected ? "white" : "black",
                  ":active": {
                    backgroundColor: theme.backgroundColor2,
                  },
                }),
                placeholder: (base) => ({
                  ...base,
                  color: loading ? "#aaa" : theme.color2,
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: "white",
                }),
                loadingIndicator: (base) => ({
                  ...base,
                  color: "red",
                  fontSize: "7px",
                }),
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Date <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              color={theme.color2}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Event <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              type="text"
              name="event"
              value={formData.event}
              onChange={handleChange}
              placeholder="Event name"
              _placeholder={{ color: theme.color2 }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Impact <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              type="text"
              name="impact"
              value={formData.impact}
              onChange={handleChange}
              placeholder="Impact level (e.g., High, Medium, Low)"
              _placeholder={{ color: theme.color2 }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Actual <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              type="number"
              name="actual"
              value={formData.actual}
              onChange={handleChange}
              placeholder="Actual value"
              _placeholder={{ color: theme.color2 }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Forecast <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              type="number"
              name="forecast"
              value={formData.forecast}
              onChange={handleChange}
              placeholder="Forecast value"
              _placeholder={{ color: theme.color2 }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Previous <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              type="number"
              name="previous"
              value={formData.previous}
              onChange={handleChange}
              placeholder="Previous value"
              _placeholder={{ color: theme.color2 }}
            />
          </FormControl>
          <Button
            type="submit"
            backgroundColor={theme.grayScales}
            color={theme.grayScales === "black" ? "white" : "black"}
            _hover={{
              backgroundColor:
                theme.grayScales === "black" ? "gray.700" : "gray.300",
              color: theme.grayScales === "black" ? "white" : "black",
            }}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default EconomicCalendar;
